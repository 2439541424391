<template>
  <section>
    <div class="container py-12 md:py-20">
      <header v-if="$slots['title-section'] || $slots.title || $slots['subtitle-aside']">
        <h3
          v-if="$slots['title-section']"
          class="relative mb-4 pb-4 font-semibold text-pink-500 after:absolute after:bottom-0 after:block after:h-1 after:w-20 after:bg-pink-500 after:content-[''] lg:mb-8"
        >
          <slot name="title-section" />
        </h3>

        <div class="lg:flex">
          <h2
            v-if="$slots.title"
            class="whitespace-pre-line text-3xl font-semibold lg:grow lg:basis-1/2 lg:text-5xl"
          >
            <slot name="title" />
          </h2>
          <div
            v-if="$slots['subtitle-aside'] || $slots['subtitle-aside-content']"
            class="mt-4 lg:mt-0 lg:basis-1/2"
          >
            <p
              v-if="$slots['subtitle-aside']"
              class="xl:text-lg"
            >
              <slot name="subtitle-aside" />
            </p>
            <slot name="subtitle-aside-content" />
          </div>
        </div>

        <p
          v-if="$slots.subtitle"
          class="mt-4 max-w-4xl xl:text-lg"
        >
          <slot name="subtitle" />
        </p>
      </header>

      <article :class="{ 'mt-12': $slots.title || $slots.subtitle }">
        <slot />
      </article>
    </div>
  </section>
</template>
