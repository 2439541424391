export const useGetTranslatedHomepageOffers = () => {
  const { $i18n: { t } } = useNuxtApp()

  return {
    getTranslatedHomepageOffers: (offer: string) => {
      const offersEmphasisMap: Record<string, { key: string, value: string }[]> = {
        default: [{ key: 'emphasis_string', value: t('pages.index.best_way_to_travel_emphasis') }],
        sunny_days: [
          { key: 'book_your_gataway_date_emphasis', value: t('pages.index.book_your_gataway_date_emphasis') },
          { key: 'book_your_gataway_amount_emphasis', value: t('pages.index.book_your_gataway_amount_emphasis') },
        ],
      }
      return offersEmphasisMap[offer] || []
    },
  }
}
