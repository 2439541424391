<script setup lang="ts">
import { COACHBUILT_OLDPEOPLE_BREAK, USER_COFFEE_4 } from '~/constants/pictures'
import { YSC_API_NEWSLETTER_ERROR } from '~/utils/error/YscErrorClasses'

const { routing } = storeToRefs(useRoutingStore())

const loading = ref(false)
const polaroid = [
  {
    img: COACHBUILT_OLDPEOPLE_BREAK,
  },
  {
    img: USER_COFFEE_4,
  },
  {
    img: COACHBUILT_OLDPEOPLE_BREAK,
  },
]

const { stackError, stackSuccess } = useToastsStore()
const { $errorManager } = useErrorManager()

const onNewsletterSubmit = async ({ email }: { email: string }) => {
  loading.value = true
  try {
    await $fetch('/api/internals/newsletter', { method: 'post', body: { email } })
  }
  catch (error) {
    loading.value = false

    type errorCode = 'email_already_exists' | 'duplicate_parameter' | 'email_is_invalid'
    type NewsletterError = {
      data: {
        statusCode: number
        data: {
          code: errorCode
          message: string
        }
      }
    }
    const typedError = error as NewsletterError
    if (typedError) {
      if (typedError && typedError.data && typedError.data.statusCode !== 400) {
        if (error instanceof Error) {
          $errorManager({ e: error, name: YSC_API_NEWSLETTER_ERROR })
        }
        stackError({ label: { text: 'commons.errors.error', needTranslation: true } })
        return
      }
      const { code }: { code: errorCode } = typedError.data.data
      const message = {
        email_already_exists: 'components.app_newsletter_blog.newsletter.errors.email_already_exists',
        duplicate_parameter: 'components.app_newsletter_blog.newsletter.errors.email_already_exists',
        email_is_invalid: 'components.app_newsletter_blog.newsletter.errors.email_is_invalid',
      }
      stackError({
        label: { text: 'commons.errors.unknown', needTranslation: true },
        description: { text: message[code], needTranslation: true },
      })
      return
    }

    stackError({ label: { text: 'commons.errors.unknown', needTranslation: true } })
  }
  loading.value = false
  stackSuccess({
    label: { text: 'components.app_newsletter_blog.newsletter.success', needTranslation: true },
  })
}
</script>

<template>
  <div class="grid gap-12 lg:grid-cols-2">
    <div class="flex lg:space-x-4">
      <div class="hidden lg:relative lg:w-56 lg:origin-bottom lg:rotate-6 lg:block">
        <div
          v-for="(item, index) in polaroid"
          :key="`polaroid_${index}`"
          class="lg:absolute lg:bg-white lg:h-24 lg:w-20 lg:p-1 lg:drop-shadow-lg"
          :class="{
            'lg:origin-bottom lg:-rotate-6': index == 1,
            'lg:origin-bottom lg:-rotate-12': index == 2,
          }"
        >
          <TwicImg
            :src="item.img"
            alt="-"
            class="h-full w-full"
          />
        </div>
      </div>
      <div class="space-y-3">
        <YscHeading
          level="3"
          class="text-gray-500"
        >
          {{ $t('components.app_newsletter_blog.blog.title') }}
        </YscHeading>
        <p class="prose text-gray-500">
          {{ $t('components.app_newsletter_blog.blog.description') }}
        </p>
        <NuxtLink
          :to="routing?.blog"
          external
          class="link link-primary"
        >
          {{ $t('components.app_newsletter_blog.blog.link') }}
        </NuxtLink>
      </div>
    </div>
    <div class="space-y-3">
      <YscHeading
        level="3"
        class="text-gray-500"
      >
        {{ $t('components.app_newsletter_blog.newsletter.title') }}
      </YscHeading>
      <p class="prose text-gray-500">
        {{ $t('components.app_newsletter_blog.newsletter.description') }}
      </p>
      <YscNewsletter
        :loading="loading"
        @submit="onNewsletterSubmit"
      />
    </div>
  </div>
</template>
